<template>
  <div class="cityList">
    <div class="cityTitle">市/县选择</div>
    <el-radio  class="radio" v-for="item in cityList" :key="item.id" v-model="currentArea" 
    @change="selectedCity" :label="item.id">{{item.label}}</el-radio>
  </div>
</template>
<script>
import Vue from 'vue';
import { Radio }  from 'element-ui';
import "element-ui/lib/theme-chalk/radio.css";

import {deepCopy} from '../../store/deepcopy.js'

Vue.use(Radio);

export default {
  methods:{
    selectedCity(id){

      // this.$emit('selectedCity', id);
      this.currentArea = id
    },
    getCityList(){
      let cuList = this.$store.state.currentCity; 
    }
  },
  data(){
    
  },
  mounted(){
  
  },
  computed:{
    currentArea:{
      get(){
        // alert( this.$store.state.currentArea )
       return this.$store.state.currentArea 
      },
      set(v){
        // console.log('set',v)
        // this.$store.state.currentArea = v;
        this.$store.commit('currentArea',v)
        this.$store.dispatch('changeCityPlateId', v)
        // this.
      }
    }, 
    city_plate_id: {
      get() {
        const a = this.$store.state.city_plate_id
      },
      set(val) {
        this.$store.dispatch('changeCityPlateId', val)
      }
    }, 
    cityList:{
      get(){
         return this.$store.getters.currentAreaList
      }
    },
    shi:{
      get(){
         return this.$store.state.shi
      }
    }
  },
  watch:{
    
    currentArea:{
      handler(v){
        if(v){
          this.$emit('selectedCity', v);
        }
     
      },
      immediate:true
    },
    shi(){ 
      this.currentArea = this.cityList[0].id
      this.city_plate_id =  this.cityList[0].id
    }
    
  },
  data(){
    return{
      radio:1
    }
  }
}
</script>
<style lang="scss" scoped>
  .cityList{
    z-index:12;
    display:flex;
    flex-wrap: wrap;
    width:98%;
    height: 54px;
    background: #F0F3F8;
    border-radius: 4px;
    margin:1%;
    box-sizing: border-box;
    justify-items: center;
    align-items: center;
    .cityTitle{
      margin:0 20px;
    }
    .currentCity{
      font-weight:bold;
    }
    .is-checked .el-radio__label{
      // font-size:20px;
      font-weight:600;
      color:#333;
    }
     .el-radio{
        margin-right:15px; 
        .el-radio__label{
          font-size:10px;
        }
     
    }
     .el-radio:first-of-type{
       .el-radio__label{
          font-size:14px;
          font-weight:bold;
        }
     }
   
  }
</style>