import request from '@/utils/request'
import requestMock from '@/utils/requestMock'
//mock数据使用  requestMock需要换成request 删除此行




export function getLocation(params) {
  return request({
    url: '/frontend/bigdata/city-plate/plan',
    method: 'post',
    params
  })
}


export function getCard(params) {
  return request({
    url: '/frontend/bigdata/city-plate/card',
    method: 'post',
    params
  })
}


//获取文章内容 city_plate_id, cate,
export function getArticle(params) {
  // if (params.city_plate_id) {
    return request({
      // url: '/mock/Article.json',
      url: '/frontend/bigdata/city-plate/policy',
      method: 'post',
      params
    })
  // }

}

//下载文件  参数 id  
export function fileDownload(params) {
  return request({
    url: '/frontend/bigdata/city-plate/policy',
    method: 'post',
    params
  })
}