<template>
    <div>
        <art />
    </div>
</template>

<script>
import Art from '../../components/common/Art'
export default {
    components:{Art}
};
</script>