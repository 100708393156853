<template>
  <div class="container">
    <city-list @selectedCity="selectedCid"></city-list>
    <div>

      <div class="text">
        <div v-if="nodata" class="nodata"> {{ message }} </div>
        <!-- <html-text v-else :introData="introData"  @selectall="selectall"></html-text> -->
        <div v-else class="textcontent" v-html="htmlData"></div>
      </div>
    </div>

    <div class="table" v-if="tableHide">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="政策文件">
        </el-table-column>
        <el-table-column prop="created_at" fixed="right" label="上传日期" width="180">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button @click.native.prevent="download(scope.$index, scope.row)" icon="el-icon-download" size="small">
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>
  </div>
</template>

<script>
import CityList from "@/components/cityreserch/CityList.vue";
import { getArticle, fileDownload } from "../../api/Article.js";
export default {
  name: "Art",
  components: { CityList },
 
  computed: {
    currentArea: {
      get() {
        return this.$store.state.currentArea;
      },
      set(v) {
        this.$store.commit("currentArea", v);
      },
    },
  },
  data() {
    return {
      nodata: false,
      message: '',
      currentCate: null,
      htmlData: "",
      tableHide: false,
      tableData: [],
    };
  },
  watch: {
    currentArea(nv, ov) {
      this.getData();
    },
  },

  mounted() {
    this.currentCate = this.$router.currentRoute.meta.articleCate;
    this.getData();
  },

  methods: {
    selectedCid() { },

    //获取数据
    getData() {
      if(this.currentArea === 0){
        return
      }
      const params = {
        city_plate_id: this.currentArea,
        cate: this.currentCate,
      };

      // alert(this.currentArea)

      getArticle(params).then((res) => {

        if (parseInt(res.code) === 1001) {
          this.nodata = true
          this.message = res.message
          this.htmlData = ''
        } else {
          this.nodata = false
          this.htmlData = res.data.content;
        }


        // this.htmlData = res.data.content;
        if (res.data.files != null && JSON.stringify(res.data.files) !== '[]') {
          this.tableHide = true;
          this.tableData = res.data.files
        }
      });

    },

    //下载
    download(index, rowData) {
      window.location.href = rowData.url

    }

  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  margin: 0 auto;

  .table {
    width: 98%;
    margin: 0 auto;

  }


}

::v-deep .el-table thead {
  color: #000;
}


.text {
  color: #333;
  width: 98%;
  background: #f0f3f8;
  height: auto;
  margin: 0 auto;
  // display: flex;
  // flex-direction: column;
  // border-radius: 5px;
}

.textcontent {
  padding-top: 20px;
  // text-align: left;
  // padding: 10px;
  // box-sizing: border-box;

}
</style>